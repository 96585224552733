var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date-selector",class:{ active: _vm.isCalendarActive || _vm.isCategoriesActive }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.value),expression:"!value"}],staticClass:"date-selector__icon-wrapper",on:{"click":function($event){return _vm.openCalendarHandler({
        category: 'Main_filter',
        event: 'when_dates_click',
      })}}},[_c('Icon',{staticClass:"date-selector__icon",attrs:{"icon":"calendar","color":"#121212"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"date-selector__icon-remove",on:{"click":function($event){$event.stopPropagation();_vm.setDate(null);
      _vm.$emit('updateDate', null);}}},[_c('Icon',{attrs:{"icon":"close","color":"#121212"}})],1),_c('DatePicker',{staticClass:"date-selector__placeholder",attrs:{"placeholder":_vm.placeholder,"editable":false,"prefix-class":"serp","format":"DD.MM.YYYY","range-separator":" — ","lang":_vm.datepickerLang,"value":_vm.value,"open":_vm.isCalendarActive || _vm.isCategoriesActive,"append-to-body":false,"range":""},on:{"open":function($event){return _vm.openCalendarHandler({
        category: 'Main_filter',
        event: 'when_dates_click',
      })},"close":_vm.closeHandler},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"date-selector__dropdown__mobile-header"},[_c('div',{staticClass:"date-selector__dropdown__mobile-header__title font-h1",domProps:{"textContent":_vm._s(_vm.$t('serp.topMenu.when'))}}),_c('div',{staticClass:"date-selector__dropdown__mobile-header__icon",on:{"click":_vm.closeHandler}},[_c('Icon',{attrs:{"icon":"close","color":"#9999A9"}})],1)]),_c('div',{staticClass:"date-selector__dropdown__switcher-wrapper"},[_c('div',{staticClass:"date-selector__dropdown__switcher"},[_c('div',{staticClass:"date-selector__dropdown__switcher__item",class:{
              'date-selector__dropdown__switcher__item--active':
                _vm.isCategoriesActive,
            },domProps:{"textContent":_vm._s(_vm.$t('serp.topMenu.monthOrPeriod'))},on:{"click":function($event){return _vm.openCategoriesHandler({
                category: 'Main_filter',
                event: 'when_dates_click',
              })}}}),_c('div',{staticClass:"date-selector__dropdown__switcher__item",class:{
              'date-selector__dropdown__switcher__item--active':
                _vm.isCalendarActive,
            },on:{"click":function($event){return _vm.openCalendarHandler(_vm.datepicker.button.exactDates.trackEvent)}}},[_c('Icon',{attrs:{"icon":"calendar","color":"#121212"}}),_c('div',{domProps:{"textContent":_vm._s(_vm.$t('serp.topMenu.exactDates'))}})],1)])]),_c('CalendarRange',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCalendarActive),expression:"isCalendarActive"}],attrs:{"value":_vm.value,"disabled-date":_vm.disabledDate},on:{"select":function($event){return _vm.acceptDate($event)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCalendarActive),expression:"isCalendarActive"}],staticClass:"date-selector__switcher"},[_c('div',{staticClass:"font-text",domProps:{"textContent":_vm._s(_vm.$t('serp.topMenu.additionalDays'))}}),_c('Switcher',{attrs:{"checked":!_vm.strictPeriod},on:{"switch":function($event){return _vm.acceptStrictPeriod(!$event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCategoriesActive),expression:"isCategoriesActive"}],staticClass:"date-selector__dropdown__categories"},[(_vm.isCategoriesActive && !_vm.isLoadDatepicker)?_c('preloader',{staticClass:"date-selector__dropdown__categories__preloader",attrs:{"visible":""}}):_vm._e(),_vm._l((_vm.dateCategories),function(category){return _c('div',{key:category.id,staticClass:"date-selector__dropdown__categories__column",style:({ gridArea: `area${category.id}` })},[_c('div',{staticClass:"date-selector__dropdown__categories__column__label font-caps",domProps:{"textContent":_vm._s(category.title)}}),_vm._l((category.options),function(categoryItem){return _c('div',{key:categoryItem.id,staticClass:"date-selector__dropdown__categories__column__item category-list",domProps:{"textContent":_vm._s(categoryItem.title)},on:{"click":function($event){return _vm.acceptDate(
                categoryItem.value,
                category.trackEvent,
                category.type
              )}}})})],2)})],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }