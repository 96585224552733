import Vue from "vue";
import App from "./app.vue";
import store from "@/store/index";
import device from "vue-device-detector";
import PortalVue from "portal-vue";
import VScrollLock from "v-scroll-lock";
import { i18n } from "@/plugins/i18n";

Vue.use(device);
Vue.use(PortalVue);
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
    allowTouchMove: (el) => el.closest(".multiselect__content-wrapper"),
  },
});

Vue.config.productionTip = false;
new Vue({ store, i18n, render: (h) => h(App) }).$mount("#main-content");
