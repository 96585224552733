<template>
  <div class="search-tools">
    <div class="search-tools__popup" v-if="mobileLocPopupIsOpen"> </div>
    <TourSelector
      class="search-tools__destination"
      :class="{'separate': !showDivider}"
      ref="tourSelector"
      place="main"
      :autofocus="autofocus"
      @updateLocation="$emit('updateLocation', $event)"
    />

    <div class="search-tools__divider" v-if="!mobileLocPopupIsOpen && showDivider"><div></div></div>

    <DateSelector
      v-show="!mobileLocPopupIsOpen"
      class="search-tools__date"
      ref="dateSelector"
      @updateDate="$emit('updateDate', $event)"
      @switchPeriod="$emit('switchPeriod', $event)"
    />

  </div>
</template>

<script>
import TourSelector from "./tour-selector.vue";
import DateSelector from "./date-selector.vue";
import {mapState} from "vuex";

export default {
  components: {
    TourSelector,
    DateSelector,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    showDivider: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapState({
      mobileLocPopupIsOpen: (state) => state.serp.mobileLocPopupIsOpen,
      dates: (state) => state.serp.dates,
      location: (state) => state.serp.location,
    }),
  },
  methods: {
    setDate(data, scrollUp = true) {
      this.$refs.dateSelector.setDate(data, scrollUp);
    },
    setLocation(data, scrollUp = true) {
      this.$refs.tourSelector.setLocation(data, scrollUp);
    },
    setLocationSearch(data) {
      this.$refs.tourSelector.setSearch(data);
    },
  },
  mounted() {
    this.setDate(this.dates, false);
    this.setLocation(this.location, false)
  },
};
</script>

<style lang="scss" scoped>
.search-tools {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__popup {
    background: #fff;
    position: fixed;
    top: 0;
    bottom:0;
    height: 100%;
    /*height: 86px;*/
    width: 100%;
    right: 0;
    left: 0;
    z-index: 100;
  }

  &__destination {
    width: 100%;
    border-radius: 12px 12px 0 0;

    &.separate{
      margin-bottom: 2px;
    }
  }

  &__date {
    width: 100%;
    border-radius: 0 0 12px 12px !important;

  }
  &__date.round-border{
    border-radius: 0!important;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: var.$gray-light;

    & > div {
      width: 100%;
      height: 1px;
      background: #d1d7e8;
    }

    @media (min-width: var.$lg) {
      width: 1px;
      height: 100%;
      background: var.$gray-light;

      & > div {
        width: 1px;
        height: 32px;
        margin: 16px 0;
        background: #d1d7e8;
      }
    }
  }

  &:focus-within > .search-tools__inputs__divider > div {
    background: var.$gray-light;
  }

  &__spaces {
    padding: 0;
    margin: 0;
    height: fit-content;
    max-height: 64px;
    position: relative;
    width: 100%;
    &-input {

      width: 100%!important;

      >>> .yt_input-container {
        height: 64px;
        width: 100%;
        background-color: var.$gray-light;
        border-radius: 0 0 12px 12px ;
        padding: 0px 16px;
        border: 1px solid var.$gray-light;
      }
      >>> .yt_input-form{
        font-size: 14px;
        font-weight: 600;
        padding: 2px 0 0 0;
      }
    }
    &__btns {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      display: flex;
      grid-gap: 12px;

      &__minus {
        position: relative;
        background: var.$green-main;
        height: 24px;
        width: 24px;
        border-radius: 4px;
        cursor: pointer;

        &::after {
          position: absolute;
          background: #fff;
          content: "";
          width: 12px;
          height: 2px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
        }

        &--disabled {
          background: var.$gray-hover;

          &::after {
            background: var.$gray-dark;
          }
        }
      }

      &__plus {
        position: relative;
        background: var.$green-main;
        height: 24px;
        width: 24px;
        border-radius: 4px;
        cursor: pointer;

        &::before {
          position: absolute;
          background: #fff;
          content: "";
          height: 12px;
          width: 2px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
        }

        &::after {
          position: absolute;
          background: #fff;
          content: "";
          width: 12px;
          height: 2px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 2px;
        }
      }
    }
  }
  @media (min-width: var.$lg) {
    flex-direction: row;

    &__destination {
      border-radius: 12px 0 0 12px !important;
    }

    &__date{
      border-radius: 0 12px 12px 0!important;
    }
    &__date.round-border {
      border-radius: 0 0 0 0 !important;
    }
    &__spaces {
      width: 186px;

      &-input {
        >>> .yt_input-container {
          width: 186px;
          border-radius: 0 12px 12px 0;
        }
      }
      &__btns {
        grid-gap: 4px;
      }
    }
  }

}
</style>
