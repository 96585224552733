<template>
  <div class="serp-tour-selector" :class="{ active: isActive }" >
    <div v-show="!isDetailSearch" class="serp-tour-selector__container">
      <Icon
        v-show="!activeTourSearchOption || mobileLocPopupIsOpen"
        icon="search"
        :color="this.searchIconColor"
        class="serp-tour-selector__icon-search"
      />

      <Multiselect
        class="multiselect-search"
        :class="{ mt6: tours.length }"
        ref="multiselectRef"
        placeholder=""
        label="tour"
        selectedLabel=""
        selectLabel=""
        deselectLabel=""
        :searchable="true"
        :options="tours"
        :loading="isLoading"
        :internal-search="false"
        :limit="3"
        :value="activeTourSearchOption"
        :preserveSearch="true"
        group-values="options"
        group-label="label"
        :group-select="false"
        :autofocus="autofocus"
        :clearOnSelect="false"
        @search-change="fetchLocation"
        @open="openHandler"
        @close="closeHandler"
        @input="querySearch = $event"
        @select="locationHandler"
      >
        <template v-slot:singleLabel="{ option }">
          <div
            class="active-item"
            v-html="option.label ? option.label : ''"
          ></div>
        </template>

        <template v-slot:noOptions>
        <span
          v-if="!isLoadingRecommended"
          class="serp-tour-selector__placeholder"
          v-text="searchLabels.startTyping"
        ></span>
          <div v-if="isLoadingRecommended" class="serp-tour-selector__loader">
            <Icon icon="loader-circle" />
          </div>
        </template>

        <template v-slot:noResult>
        <span
          class="serp-tour-selector__placeholder"
          v-text="searchLabels.nothingToShow"
        ></span>
        </template>

        <template slot="placeholder">
          <span v-text="placeholder"></span>
        </template>

        <template slot="caret"><div class="empty"></div></template>

        <template #beforeList>
          <div class="serp-tour-selector__before-list">
            <location-nearby
              @trigger="onLocationTrigger"
              @get-current-position="onGetCurrentPosition"
              @pending="isLoading = $event"
            />
          </div>
        </template>

        <template v-slot:option="{ option, search }">
          <!-- TODO: добавить поле trackEvent в API c тайтлом экшена аналитики-->
          <!-- заменить на @click="trackEventSelector(option.trackEvent)-->
          <div
            v-if="option.id"
            class="search-item"
            @click="
            trackEvent(
              place == 'main' ? 'Main page Search' : 'Header Search',
              'Click Suggested option',
              option.type,
              {
                item: option.label,
              }
            )
          "
          >
            <div class="description">
              <span class="item-title" v-html="option.label"></span>
              <span v-if="option.description" class="item-description">
              {{ search }}
            </span>
            </div>
            <div class="type" v-if="option.type !== 'tour'">
              <span class="item-type">{{ option.count }}</span>
            </div>
          </div>

          <div v-else class="search-item">
            <div class="description--group">
              <span class="item-title" v-html="option.$groupLabel"></span>
            </div>
          </div>
        </template>
      </Multiselect>

      <div
        v-show="activeTourSearchOption && !isActive"
        class="serp-tour-selector__icon-remove"
        @click.stop="locationHandler(null)"
      >
        <Icon icon="close" color="#121212" />
      </div>

      <div
        v-if="isActive"
        class="serp-tour-selector__icon-close"
        @click="closeHandler"
      >
        <Icon icon="close" color="#121212" />
      </div>
    </div>
    <div v-show="isDetailSearch"  class="serp-tour-selector__container" v-click-outside="hideDropDown">
      <input
        class="serp-tour-selector__detail-input"
        :placeholder="detailPlaceholder"
        ref="searchInputRef"
        @input="changeSearchValue"
        v-on:focus="showDropDown"
        :value="querySearch"/>
      <Icon
        v-show="!activeTourSearchOption || mobileLocPopupIsOpen"
        icon="search"
        :color="this.searchIconColor"
        class="serp-tour-selector__icon-search"
      />
      <div class="serp-tour-selector__dropdown"
           ref="searchDropdown"
      >
        <div class="dropdown__header">
          <div class="dropdown__header-left">Направления</div>
          <div class="dropdown__header-right">Регионы/города</div>
        </div>
        <div class="dropdown__left" ref="searchDropdownLeft">

          <div class="dropdown__left-item"
               v-for="option in detailSearchOptions"
               :key="option.id"
               @mouseenter="showRightBlock(option.id)"
               @click.stop="setDetailLocation(option)">
            <div class="dropdown__left-item-name"
                 :ref="`searchDropdownLeftItem${option.id}`"
            >
              {{option.label}}
            </div>
            <div class="dropdown__right" :ref="`searchDropdownRightItem${option.id}`">
              <div class="dropdown__right-item-name"
                   v-for="detailOption in option.options"
                   :key="detailOption.id"
                   @click.stop="setDetailLocation(detailOption)">
                {{detailOption.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Icon from "@/components/icons/index.vue";
import { i18n } from "@/plugins/i18n";
import { axiosLocations } from "@/services/scopes/axios-locations.js";
import { ytEvents } from "@/plugins/analytics";
import Multiselect from "vue-multiselect";
import LocationNearby from "@/components/organisms/location-nearby/index.vue";
import helper from "@/mixins/helper.js";
import {mapMutations, mapState} from "vuex";
import {abTestController} from "@/plugins/abtest";



export default {
  name: "tour-selector",
  mixins: [helper],
  components: {
    Icon,
    Multiselect,
    LocationNearby,

  },
  directives: {
    "click-outside": {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        fakeWindow.addEventListener("click", el.clickOutsideEvent);
      },
      unbind(el) {
        fakeWindow.removeEventListener("click", el.clickOutsideEvent);
      },
    }
  },
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    place: {
      validator: function (prop) {
        return ["", "main", "header"].indexOf(prop) !== -1;
      },
      default: "",
    },
  },
  data: () => ({
    isActive: false,
    isLoading: false,
    isLoadingRecommended: false,
    searchLabels: {
      startTyping: i18n.t("tourSelector.startTyping"),
      nothingToShow: i18n.t("tourSelector.nothingToShow"),
      chooseDirection: i18n.t("tourSelector.chooseDirection"),
    },
    detailPlaceholder: i18n.t("serp.topMenu.where"),
    tours: [],
    querySearch: "",
    activeTourSearchOption: null,
    isMobile: false,
    detailSearchOptions:[
      {
        "id": 279,
        "type": "country",
        "code": "россия",
        "displayType": "Страна",
        "label": "Россия",
        options: [
          {
            "id": 716,
            "type": "region",
            "code": "алтай",
            "displayType": "Регион",
            "label": "Алтай",
          },
          {
            "id": 1339,
            "type": "region",
            "code": "dagestan",
            "displayType": "Регион",
            "label": "Дагестан",
          },
          {
            "id": 715,
            "type": "region",
            "code": "кавказ",
            "displayType": "Регион",
            "label": "Кавказ",

          },
          {
            "id": 695,
            "type": "region",
            "code": "байкал",
            "displayType": "Регион",
            "label": "Байкал",
          },
          {
            "id": 694,
            "type": "region",
            "code": "камчатка",
            "displayType": "Регион",
            "label": "Камчатка",
          },
          {
            "id": 717,
            "type": "region",
            "code": "карелия",
            "displayType": "Регион",
            "label": "Карелия",
          },
          {
            "id": 935,
            "type": "region",
            "code": "murmanskaya_oblast",
            "displayType": "Регион",
            "label": "Кольский полуостров",
          },
          {
            "id": 472,
            "type": "region",
            "code": "краснодарский_край",
            "displayType": "Регион",
            "label": "Краснодарский край",
          }
        ]
      },
      {
        "id": 16,
        "type": "continent",
        "code": "австралия_и_океания",
        "displayType": "Континент",
        "label": "Австралия и Океания",
        options: [
          {
            "id": 429,
            "type": "country",
            "code": "австралия",
            "displayType": "Страна",
            "label": "Австралия",
          },
          {
            "id": 434,
            "type": "country",
            "code": "новая_зеландия",
            "displayType": "Страна",
            "label": "Новая Зеландия",
          }
        ]
      },
      {
        "id": 13,
        "type": "continent",
        "code": "африка",
        "displayType": "Континент",
        "label": "Африка",
        options: [
          {
            "id": 369,
            "type": "country",
            "code": "марокко",
            "displayType": "Страна",
            "label": "Марокко",
          },
          {
            "id": 371,
            "type": "country",
            "code": "намибия",
            "displayType": "Страна",
            "label": "Намибия",
          },
          {
            "id": 391,
            "type": "country",
            "code": "юар",
            "displayType": "Страна",
            "label": "ЮАР",
          },
          {
            "id": 358,
            "type": "country",
            "code": "кения",
            "displayType": "Страна",
            "label": "Кения",
          },
          {
            "id": 352,
            "type": "country",
            "code": "египет",
            "displayType": "Страна",
            "label": "Египет",
          },
          {
            "id": 377,
            "type": "country",
            "code": "сейшелы",
            "displayType": "Страна",
            "label": "Сейшелы",
          },
          {
            "id": 366,
            "type": "country",
            "code": "мадагаскар",
            "displayType": "Страна",
            "label": "Мадагаскар",
          }
        ]
      },
      {
        "id": 12,
        "type": "continent",
        "code": "азия",
        "displayType": "Континент",
        "label": "Азия",
        options: [
          {
            "id": 337,
            "type": "country",
            "code": "япония",
            "displayType": "Страна",
            "label": "Япония",
          },
          {
            "id": 338,
            "type": "country",
            "code": "южная_корея",
            "displayType": "Страна",
            "label": "Южная Корея",
          },
          {
            "id": 313,
            "type": "country",
            "code": "китай",
            "displayType": "Страна",
            "label": "Китай",
          },
          {
            "id": 300,
            "type": "country",
            "code": "вьетнам",
            "displayType": "Страна",
            "label": "Вьетнам",
          },
          {
            "id": 318,
            "type": "country",
            "code": "малайзия",
            "displayType": "Страна",
            "label": "Малайзия",
          },
          {
            "id": 336,
            "type": "country",
            "code": "шри-ланка",
            "displayType": "Страна",
            "label": "Шри-Ланка",
          },
          {
            "id": 302,
            "type": "country",
            "code": "индия",
            "displayType": "Страна",
            "label": "Индия",
          },
          {
            "id": 331,
            "type": "country",
            "code": "таиланд",
            "displayType": "Страна",
            "label": "Таиланд",
          },
          {
            "id": 955,
            "type": "region",
            "code": "bali",
            "displayType": "Регион",
            "label": "Бали",
          }
        ]
      },
      {
        "id": 1327,
        "type": "region",
        "code": "blizhniy_vostok",
        "displayType": "Регион",
        "label": "Ближний Восток",
        options: [
          {
            "id": 333,
            "type": "country",
            "code": "турция",
            "displayType": "Страна",
            "label": "Турция",
          },
          {
            "id": 324,
            "type": "country",
            "code": "оман",
            "displayType": "Страна",
            "label": "Оман",
          },
          {
            "id": 306,
            "type": "country",
            "code": "иран",
            "displayType": "Страна",
            "label": "Иран",
          }
        ]
      },
      {
        "id": 11,
        "type": "continent",
        "code": "европа",
        "displayType": "Континент",
        "label": "Европа",
        options: [
          {
            "id": 265,
            "type": "country",
            "code": "исландия",
            "displayType": "Страна",
            "label": "Исландия",
          },
          {
            "id": 267,
            "type": "country",
            "code": "италия",
            "displayType": "Страна",
            "label": "Италия",
          },
          {
            "id": 287,
            "type": "country",
            "code": "франция",
            "displayType": "Страна",
            "label": "Франция",
          },
          {
            "id": 266,
            "type": "country",
            "code": "испания",
            "displayType": "Страна",
            "label": "Испания",
          },
          {
            "id": 291,
            "type": "country",
            "code": "швейцария",
            "displayType": "Страна",
            "label": "Швейцария",
          },
          {
            "id": 276,
            "type": "country",
            "code": "норвегия",
            "displayType": "Страна",
            "label": "Норвегия",
          },
          {
            "id": 286,
            "type": "country",
            "code": "финляндия",
            "displayType": "Страна",
            "label": "Финляндия",
          },
          {
            "id": 278,
            "type": "country",
            "code": "португалия",
            "displayType": "Страна",
            "label": "Португалия",
          },
          {
            "id": 261,
            "type": "country",
            "code": "греция",
            "displayType": "Страна",
            "label": "Греция",
          },
          {
            "id": 289,
            "type": "country",
            "code": "черногория",
            "displayType": "Страна",
            "label": "Черногория",
          }
        ]
      },
      {
        "id": 14,
        "type": "continent",
        "code": "северная_америка",
        "displayType": "Континент",
        "label": "Северная Америка",
        options: [
          {
            "id": 414,
            "type": "country",
            "code": "сша",
            "displayType": "Страна",
            "label": "США",
          },
          {
            "id": 407,
            "type": "country",
            "code": "мексика",
            "displayType": "Страна",
            "label": "Мексика",
          },
          {
            "id": 403,
            "type": "country",
            "code": "канада",
            "displayType": "Страна",
            "label": "Канада",
          }
        ]
      },
      {
        "id": 15,
        "type": "continent",
        "code": "южная_америка",
        "displayType": "Континент",
        "label": "Южная Америка",
        options: [
          {
            "id": 417,
            "type": "country",
            "code": "аргентина",
            "displayType": "Страна",
            "label": "Аргентина",
          },
          {
            "id": 418,
            "type": "country",
            "code": "боливия",
            "displayType": "Страна",
            "label": "Боливия",
          },
          {
            "id": 419,
            "type": "country",
            "code": "бразилия",
            "displayType": "Страна",
            "label": "Бразилия",
          },
          {
            "id": 422,
            "type": "country",
            "code": "колумбия",
            "displayType": "Страна",
            "label": "Колумбия",
          },
          {
            "id": 424,
            "type": "country",
            "code": "перу",
            "displayType": "Страна",
            "label": "Перу",
          },
          {
            "id": 427,
            "type": "country",
            "code": "чили",
            "displayType": "Страна",
            "label": "Чили",
          },
          {
            "id": 390,
            "type": "country",
            "code": "эфиопия",
            "displayType": "Страна",
            "label": "Эфиопия",
          }
        ]
      }
    ]
  }),
  computed: {
    ...mapState({
      mobileLocPopupIsOpen: (state) => state.serp.mobileLocPopupIsOpen,
      serpWindowPath: (state) => state.serp.serpWindowPath,
      countryCode: (state) => state.country.countryCode,
    }),
    abtestDetailSearch () {
      return abTestController.getSegment(16335) === "B";
    },
    placeholder() {
      return this.querySearch
        ? this.querySearch
        : this.$t("serp.topMenu.where");
    },
    isDetailSearch() {
      return this.querySearch?.length == 0
        && !this.isMobile
        && i18n.locale == 'ru'
        && this.abtestDetailSearch;
    },
    searchIconColor() {
      return this.isActive && this.isMobile ? "#9999A9" : "#121212";
    },
  },
  watch:{
    isDetailSearch() {
      this.$nextTick(() => {
        this.detailPlaceholder = this.$t("serp.topMenu.where");
        this.$refs.searchInputRef.focus();
      });
    },
    serpWindowPath() {
      this.isDetailSearch();
    }
  },
  methods: {
    ...mapMutations({
      setMobileLocPopupIsOpen: "serp/setMobileLocPopupIsOpen",
    }),
    setIsMobile() {
      this.isMobile = fakeWindow.innerWidth < 943;
    },
    showDropDown() {
      let id = this.detailSearchOptions[0].id;
      this.setActiveSearchDetailItem(id);
      this.$refs.searchDropdown.style.display = "block";
    },
    hideDropDown() {
      this.$refs.searchDropdown.style.display = "none"
      this.detailPlaceholder = this.$t("serp.topMenu.where");
    },
    showRightBlock(id) {
      this.detailPlaceholder = "";
      this.setActiveSearchDetailItem(id);
    },
    setActiveSearchDetailItem(id){
      for (let key in this.$refs) {
        if (key.indexOf('searchDropdownRightItem') !== -1){
          this.$refs[key][0].style.display = "none";
        }
        if (key.indexOf('searchDropdownLeftItem') !== -1){
          this.$refs[key][0].style.color = "#121212";
        }
      }
      this.$refs[`searchDropdownLeftItem${id}`][0].style.color = "#87A038";
      this.$refs[`searchDropdownRightItem${id}`][0].style.display = "block";
    },
    setDetailLocation(evt) {
      this.trackEvent(
        this.place == 'main' ? 'Main page Search' : 'Header Search',
        'Click Suggested option',
        evt.type,
        {
          item: evt.label,
        }
      )
      this.locationHandler(evt);
    },
    locationHandler(evt) {
      this.querySearch = evt?.label || "";
      this.activeTourSearchOption = evt;
      this.isLoading = false;
      this.isActive = false;
      this.$emit("updateLocation", this.activeTourSearchOption);
    },
    changeSearchValue(evt){
      this.querySearch = evt.target.value;
      this.$refs.multiselectRef.updateSearch(evt.target.value);
      this.$refs.multiselectRef.activate();
      this.$refs.multiselectRef.$el.focus()
    },
    onLocationTrigger() {
      this.$refs.multiselectRef.deactivate();
    },
    onGetCurrentPosition({ latitude, longitude }) {
      this.locationHandler({
        type: "distance",
        label: this.$t("locationNearby.label"),
        lat: latitude,
        lon: longitude,
        radius: 1000,
      });
    },
    async openHandler() {
      if (this.isMobile) {
        this.setMobileLocPopupIsOpen(true);
        const scrollY =
          document.documentElement.style.getPropertyValue("--scroll-y");
        const body = document.body;
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = `-${scrollY}`;
      }

      this.emitHeaderShow(false);
      this.$refs.multiselectRef.$refs.search.removeAttribute("readonly");
      this.isLoadingRecommended = true;
      this.isActive = true;
      this.$emit("isOpen", true);

      if (!this.querySearch) {
        await this.getRecommendedLocations();
      } else if (!this.tours?.length) {
        const {
          data: {
            data: { autocomplete: locations },
          },
        } = await axiosLocations.getList(this.querySearch);

        if (locations) {
          this.tours = this.parseLocations(locations);
        }
      }

      this.isLoadingRecommended = false;
    },
    closeHandler() {
      if (this.isMobile) {
        this.setMobileLocPopupIsOpen(false);
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = "";
        body.style.top = "";
        fakeWindow.scrollTo(0, parseInt(scrollY || "0") * -1);
      }

      this.emitHeaderShow(true);
      this.isActive = false;
      this.$emit("isOpen", false);
    },
    trackEvent(category, name, label, params) {
      ytEvents.track(category, name, label, params);
    },
    async fetchLocation(evt) {
      this.querySearch = evt;

      this.$emit("searchChange", evt);

      if (!this.querySearch) {
        await this.getRecommendedLocations();
        return;
      }

      this.isLoading = true;

      const {
        data: {
          data: { autocomplete: locations },
        },
      } = await axiosLocations
        .getList(this.querySearch)
        .catch((e) => console.log(e));

      if (locations) {
        this.tours = this.parseLocations(locations);
      }

      this.isLoading = false;
    },
    async getRecommendedLocations() {
      let initTours = [];

      // Ожидаем информацию
      while (fakeWindow.geoInfo === undefined) {
        await this.sleep(100);
      }

      const {
        data: {
          data: { popular_items, search_history },
        },
      } = await axiosLocations.getLocationsByCountryCode(this.countryCode).catch((e) => console.log(e));

      if (search_history) {
        initTours.push({
          type: "history",
          label: this.$t("serp.topMenu.history"),
          options: this.parseLocationOptions(search_history),
        });
      }

      if (popular_items) {
        initTours.push({
          type: "popular",
          label: this.$t("serp.topMenu.popular"),
          options: this.parseLocationOptions(popular_items),
        });
      }

      this.tours = initTours;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    parseLocations(locations) {
      let filteredlLcations = locations.filter(({ type }) => type !== "type");

      if (this.isIosApp) {
        filteredlLcations = filteredlLcations.filter((el) => {
          if (el.type === "location") {
            return false;
          } else if (el.type === "region") {
            return el.id !== 470;
          }
          return true;
        });
      }
      return Object.keys(filteredlLcations)
        .reduce(
          (acc, key) => {
            const type = acc.findIndex(
              ({ type }) => type === filteredlLcations[key].type
            );
            const typeBetter = acc.findIndex(
              ({ type }) => type === 'better'
            );
            if (acc?.[type]) {
              acc[type].options.push({
                ...filteredlLcations[key],
                count: i18n.tc(
                  "tourSelector.tours",
                  filteredlLcations[key].tour_count
                ),
              });
            }
            if(key < 4 && this.querySearch?.length){
              acc[typeBetter].options.push({
                ...filteredlLcations[key],
                count: i18n.tc(
                  "tourSelector.tours",
                  filteredlLcations[key].tour_count
                ),
              });
            }
            return acc;
          },
          [
            {
              type: "better",
              label: this.$t("serp.topMenu.better"),
              options: [],
            },
            {
              type: "history",
              label: this.$t("serp.topMenu.history"),
              options: [],
            },
            {
              type: "country",
              label: this.$t("serp.topMenu.countries"),
              options: [],
            },
            {
              type: "continent",
              label: this.$t("serp.topMenu.continents"),
              options: [],
            },
            {
              type: "region",
              label: this.$t("serp.topMenu.regions"),
              options: [],
            },
            {
              type: "location",
              label: this.$t("serp.topMenu.destinations"),
              options: [],
            },
            {
              type: "tour",
              label: this.$t("serp.topMenu.tours"),
              options: [],
            },
            {
              type: "popular",
              label: this.$t("serp.topMenu.popular"),
              options: [],
            },
          ]
        )
        .filter(({ options }) => options.length);
    },
    parseLocationOptions(locations) {
      let filteredlLcations = locations.filter(({ type }) => type !== "type");

      if (this.isIosApp) {
        filteredlLcations = filteredlLcations.filter((el) => {
          if (el.type === "location") {
            return false;
          } else if (el.type === "region") {
            return el.id !== 470;
          }
          return true;
        });
      }

      return Object.keys(filteredlLcations).reduce((acc, key) => {
        acc.push({
          ...filteredlLcations[key],
          count: i18n.tc(
            "tourSelector.tours",
            filteredlLcations[key].tour_count
          ),
        });

        return acc;
      }, []);
    },
    setSearch(text) {
      this.$refs.multiselectRef.updateSearch(text);
    },
    setLocation(location, scrollUp = true) {
      const loc = location?.[0] || location;
      if (loc && this.isMobile && scrollUp) {
        this.closeHandler();
        this.scrollTop();
      }
      //if (loc) {
      this.activeTourSearchOption = loc;
      this.querySearch = loc?.label || "";
      // }
    },
    scrollTop() {
      const body = document.body;
      const scrollY = body.style.top;
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  },
  mounted() {
    this.setIsMobile();
    const input = this.$refs.multiselectRef.$refs.search;
    this.$refs.searchInputRef.focus();
    input.setAttribute("readonly", true);
    input.addEventListener(
      "blur",
      () => {
        input.setAttribute("readonly", true);
      },
      true
    );

    fakeWindow.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${fakeWindow.scrollY}px`
      );
    });
    fakeWindow.addEventListener("resize", this.setIsMobile);
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("scroll",document.documentElement);
    fakeWindow.removeEventListener("blur",this.$refs.multiselectRef.$refs.search);
    fakeWindow.removeEventListener("resize", this.setIsMobile);
  },
};
</script>

<style lang="scss">
@use "@/assets/scss/libraries/elements/serp-tour-selector.scss";

.item-title {
  b {
    color: var.$teal-400;
  }
}

.mt6 .multiselect__content {
  margin-bottom: 6px;
}
</style>
<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.serp-tour-selector {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  background: var.$gray-light;
  border: 1px solid transparent;
  border-radius: 12px 12px 0 0;
  cursor: pointer;

  &.active {
    @media (max-width: 942px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 20px;
      border-radius: 8px;
      position: fixed;
      height: 48px;
      top: 16px;
      left: 16px;
      z-index: 100001;
      /*margin: 16px 48px 0 16px;*/
      max-width: calc(100% - 64px);
      padding: 16px 20px 16px 32px;
      box-sizing: border-box;

      .serp-tour-selector__icon-search {
        position: absolute;
        left: 21px;
        z-index: 100002;
      }
    }
  }

  &__container{
    width: 100%;
    display: flex;
    align-items: center;

    @media (min-width: var.$lg) {
      position: relative;
    }
  }

  &__icon-close {
    position: absolute;
    right: -29px;
  }

  &__placeholder {
    margin-left: 20px;
    & > span {
      width: 200px;
    }
    &:hover {
      background: var.$white !important;
    }
  }

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;

    & > svg {
      animation: spin 1s linear infinite;
    }
  }

  &__before-list {
    margin: 16px;
  }

  &__detail-input{
    width: 100%;
    padding: 23px 40px 23px 16px;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 12px 0 0 12px;
    box-sizing: border-box;
    background-color: #eeeef2;

    &::-moz-placeholder{
      color: #9999a9;
    }
    &::-webkit-input-placeholder{
      color: #9999a9;
    }
    &:focus::-webkit-input-placeholder {
      color: transparent
    }

    &:focus::-moz-placeholder {
      color: transparent
    }

    &:focus:-moz-placeholder {
      color: transparent
    }

    &:focus:-ms-input-placeholder {
      color: transparent
    }
    /*&:focus-within {
      border: 1px solid var.$black;
    }
    &:focus{
      background-color: #fff;
    }*/
  }
  &__dropdown{
    position: absolute;
    border-radius: 24px;
    border: 1px solid var(--grey-light, #EEEEF2);
    background: var(--white, #FFF);
    box-shadow: -2px 1px 28px 0px rgba(153, 153, 169, 0.30);
    top: 57px;
    left: -8px;
    box-sizing: border-box;
    padding: 24px;
    width: 604px;
    height: 485px;
    transition: var.$transition-default;
    display: none;
    z-index:6;
    overflow: hidden;
  }
}

.search-item {
  position: relative;

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-title {
  margin: 0;

  b {
    color: var.$teal-400;
  }
}

.description {
  max-width: 70%;

  color: var.$black;

  overflow: hidden;
  white-space: break-spaces;

  &--group {
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    color: var.$secondary;
    text-transform: uppercase;
  }
}

.item-description {
  margin: 0;

  color: var.$black;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.type {
  vertical-align: middle;
}
.dropdown {
  &__header{
    color: var.$text-dark;
    font-family: SF Pro Text,arial,helvetica,sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: .22px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    grid-gap: 24px;
    margin-bottom: 10px;
    &-left{
      width: 267px;
    }
    &-right{
      width: 267px;
    }
  }
  &__left {
    width: 263px;
    height: 438px;
    border-right: 1px solid var.$gray-light;
    &::-webkit-scrollbar-thumb {
      background: var.$gray-dark;
      border-radius: var.$rounded-16;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track{
      margin: 0;
    }
    overflow-y: scroll;
    &-item {
      display: flex;
      padding-right: 4px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      overflow-y: scroll;
      box-sizing: border-box;
      &-name {
        color: var.$black;
        font-family: var.$font-family-sf-pro-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
        border-bottom: 1px solid var.$gray-light;
        width: 100%;
        height: 100%;
        padding: 16px 0;
      }
    }
  }

  &__right {
    position: absolute;
    min-width: 263px;
    right: 24px;
    background: #fff;
    top: 45px;
    flex-direction: column;
    display:none;
    bottom: 0;
    border-right: 1px solid var.$gray-light;
    &::-webkit-scrollbar-thumb {
      background: var.$gray-dark;
      border-radius: var.$rounded-16;
    }
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track{
      margin: 0;
    }
    overflow-y: scroll;
    &-item {
      &-name {
        cursor: pointer;
        display: flex;
        padding: 16px 0;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        color: var.$black;
        font-family: var.$font-family-sf-pro-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
        border-bottom: 1px solid var.$gray-light;
        margin-right: 12px;
        &:hover{
          color: var.$green-2;
        }
      }

    }
  }
}
.dropdown__left-item:first-child .dropdown__right{
  display: flex;
}
.dropdown__left-item:first-child .dropdown__left-item-name{
  color: var.$green-2;
}
.item-type {
  padding: 0;

  color: var.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
