<template>
  <div class="app home">
    <MainPreview />
  </div>
</template>

<script>
import MainPreview from "@/components/organisms/main-preview/index.vue";

export default {
  components: {
    MainPreview,
  },
};
</script>

<style lang="scss">
// TODO: Перенести в pages/index/app.vue, чтобы стили не дублировались
@use "@/assets/scss/global.scss";
@use "@/assets/scss/libraries/elements/date-selector.scss";

.home {
  position: relative;
  top: 0;
  z-index: 4;
  height: 600px;
  background: #7c7c7c;

  @media (min-width: var.$lg) {
    height: 700px;
  }
}
</style>
