<template>
  <div
    class="date-selector"
    :class="{ active: isCalendarActive || isCategoriesActive }"
  >
    <div
      v-show="!value"
      class="date-selector__icon-wrapper"
      @click="
        openCalendarHandler({
          category: 'Main_filter',
          event: 'when_dates_click',
        })
      "
    >
      <Icon icon="calendar" color="#121212" class="date-selector__icon" />
    </div>

    <div
      v-show="value"
      class="date-selector__icon-remove"
      @click.stop="
        setDate(null);
        $emit('updateDate', null);
      "
    >
      <Icon icon="close" color="#121212" />
    </div>

    <DatePicker
      :placeholder="placeholder"
      :editable="false"
      prefix-class="serp"
      class="date-selector__placeholder"
      format="DD.MM.YYYY"
      range-separator=" — "
      :lang="datepickerLang"
      :value="value"
      :open="isCalendarActive || isCategoriesActive"
      :append-to-body="false"
      @open="
        openCalendarHandler({
          category: 'Main_filter',
          event: 'when_dates_click',
        })
      "
      @close="closeHandler"
      range
    >
      <template v-slot:content>
        <div class="date-selector__dropdown__mobile-header">
          <div
            class="date-selector__dropdown__mobile-header__title font-h1"
            v-text="$t('serp.topMenu.when')"
          ></div>

          <div
            class="date-selector__dropdown__mobile-header__icon"
            @click="closeHandler"
          >
            <Icon icon="close" color="#9999A9" />
          </div>
        </div>

        <div class="date-selector__dropdown__switcher-wrapper">
          <div class="date-selector__dropdown__switcher">
            <div
              class="date-selector__dropdown__switcher__item"
              :class="{
                'date-selector__dropdown__switcher__item--active':
                  isCategoriesActive,
              }"
              v-text="$t('serp.topMenu.monthOrPeriod')"
              @click="
                openCategoriesHandler({
                  category: 'Main_filter',
                  event: 'when_dates_click',
                })
              "
            ></div>

            <div
              class="date-selector__dropdown__switcher__item"
              :class="{
                'date-selector__dropdown__switcher__item--active':
                  isCalendarActive,
              }"
              @click="
                openCalendarHandler(datepicker.button.exactDates.trackEvent)
              "
            >
              <Icon icon="calendar" color="#121212" />
              <div v-text="$t('serp.topMenu.exactDates')"></div>
            </div>
          </div>
        </div>

        <CalendarRange
          v-show="isCalendarActive"
          :value="value"
          :disabled-date="disabledDate"
          @select="acceptDate($event)"
        >
        </CalendarRange>

        <div v-show="isCalendarActive" class="date-selector__switcher">
          <div
            class="font-text"
            v-text="$t('serp.topMenu.additionalDays')"
          ></div>
          <Switcher
            :checked="!strictPeriod"
            @switch="acceptStrictPeriod(!$event)"
          />
        </div>

        <div
          v-show="isCategoriesActive"
          class="date-selector__dropdown__categories"
        >
          <preloader class="date-selector__dropdown__categories__preloader"
                     v-if="isCategoriesActive && !isLoadDatepicker"
                     visible />

          <div
            class="date-selector__dropdown__categories__column"
            v-for="category of dateCategories"
            :key="category.id"
            :style="{ gridArea: `area${category.id}` }"
          >
            <div
              class="
                date-selector__dropdown__categories__column__label
                font-caps
              "
              v-text="category.title"
            ></div>
            <div
              class="
                date-selector__dropdown__categories__column__item
                category-list
              "
              v-for="categoryItem in category.options"
              v-text="categoryItem.title"
              :key="categoryItem.id"
              @click="
                acceptDate(
                  categoryItem.value,
                  category.trackEvent,
                  category.type
                )
              "
            ></div>
          </div>
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { parse } from "date-fns";
import Icon from "@/components/icons/index.vue";
import DatePicker from "vue2-datepicker";
import Switcher from "@/components/ui-kit/switch/index.vue";
import lang from "vue2-datepicker/locale/es/ru";
import { mapActions, mapMutations, mapState } from "vuex";
import { i18n } from "@/plugins/i18n";
import { ytEvents } from "@/plugins/analytics";
import helper from "@/mixins/helper.js";
import Preloader from "@/components/ui-kit/preloader/index.vue";

const { CalendarRange } = DatePicker;
lang.monthFormat = "MMMM";

export default {
  name: "date-selector",
  mixins: [helper],
  components: {
    Preloader,
    DatePicker,
    CalendarRange,
    Icon,
    Switcher,
  },
  computed: {
    ...mapState({
      datepicker: (state) => state.dateCategories.datepicker,
      dateCategories: (state) => state.dateCategories.dateCategories,
      isLoadDatepicker: (state) => state.dateCategories.isLoad,
      mobileDatePopupIsOpen: (state) => state.serp.mobileDatePopupIsOpen,
      strictPeriod: (state) => state.serp.strictPeriod,
    }),
  },
  data() {
    return {
      datepickerLang: i18n.t("dateSelector.datepickerLang"),
      placeholder: this.$t("serp.topMenu.when"),
      isCategoriesActive: false,
      isCalendarActive: false,
      value: null,
      isMobile: false
    };
  },
  methods: {
    ...mapActions({
      fetchDatepicker: "dateCategories/fetchDatepicker",
    }),
    ...mapMutations({
      setMobileDatePopupIsOpen: "serp/setMobileDatePopupIsOpen",
      setStrictPeriod: "serp/setStrictPeriod",
    }),
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    },
    setIsMobile() {
      this.isMobile = fakeWindow.innerWidth < 943;
    },
    isValidDate(date) {
      return date instanceof Date && !isNaN(date);
    },
    acceptStrictPeriod(strictPeriod) {
      this.setStrictPeriod(strictPeriod);
      this.strictPeriod = strictPeriod;
      this.$emit("switchPeriod", strictPeriod);
    },
    acceptDate(date, eventIdentifier, type) {
      const [startValue, endValue] = date;
      this.value = [];

      if (startValue) {
        this.value.push(
          this.isValidDate(startValue)
            ? startValue
            : parse(startValue, "dd.MM.yyyy", new Date())
        );
      } else {
        this.value.push("");
      }

      if (endValue) {
        this.value.push(
          this.isValidDate(endValue)
            ? endValue
            : parse(endValue, "dd.MM.yyyy", new Date())
        );
      } else {
        this.value.push("");
      }

      this.value.push(type);

      this.$emit("updateDate", this.value);

      if (typeof eventIdentifier !== "undefined") {
        this.trackEvent(eventIdentifier.category, eventIdentifier.event);
      }
      this.closeHandler();
    },
    setDate(date, scrollUp = true) {
      this.value = !date ? date : date[0];
      if (this.isMobile && this.value && scrollUp) {
        this.scrollTop();
      }
    },
    openCalendarHandler(eventIdentifier = undefined) {
      if (this.isMobile) {
        this.setMobileDatePopupIsOpen(true);
        const scrollY =
          document.documentElement.style.getPropertyValue("--scroll-y");
        const body = document.body;
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = `-${scrollY}`;
      }

      this.emitHeaderShow(false);
      this.isCalendarActive = true;
      this.isCategoriesActive = false;

      if (typeof eventIdentifier !== "undefined") {
        this.trackEvent(eventIdentifier.category, eventIdentifier.event);
      }
    },
    openCategoriesHandler(eventIdentifier = undefined) {
      if (!this.isLoadDatepicker) {
        this.fetchDatepicker();
      }

      if (this.isMobile) {
        this.setMobileDatePopupIsOpen(true);
        const scrollY =
          document.documentElement.style.getPropertyValue("--scroll-y");
        const body = document.body;
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = `-${scrollY}`;
      }

      this.isCategoriesActive = true;
      this.isCalendarActive = false;
      if (typeof eventIdentifier !== "undefined") {
        this.trackEvent(eventIdentifier.category, eventIdentifier.event);
      }
    },
    closeHandler() {
      if (this.isMobile) {
        this.setMobileDatePopupIsOpen(false);
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = "";
        body.style.top = "";
        fakeWindow.scrollTo(0, parseInt(scrollY || "0") * -1);
      }

      this.emitHeaderShow(true);
      this.isCalendarActive = false;
      this.isCategoriesActive = false;
    },
    disabledDate(date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return date < currentDate;
    },
    scrollTop() {
      const body = document.body;
      const scrollY = body.style.top;
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  },
  mounted() {
    this.setIsMobile();
    fakeWindow.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${fakeWindow.scrollY}px`
      );
    });
    fakeWindow.addEventListener("resize", this.setIsMobile);
  },
  beforeDestroy() {
    fakeWindow.removeEventListener("scroll", document.documentElement);
    fakeWindow.removeEventListener("resize", this.setIsMobile);
  },
};
</script>

<style lang="scss" scoped>
.date-selector {
  position: relative;
  height: 64px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  background-color: var.$gray-light;
  border: 1px solid transparent;
  border-radius: 8px;

  &.active {
    border: 1px solid var.$black;
    background-color: var.$white;
  }

  &__placeholder {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 100%;
    color: transparent;

    &-wrapper {
      position: absolute;
      top: 49%;
      right: 21px;
      transform: translate(0, -50%);
      width: 20px;
      height: 18px;
      cursor: pointer;
    }

    &-remove {
      position: absolute;
      right: 21px;
      cursor: pointer;
    }
  }

  &__dropdown {
    &__mobile-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px 32px;
      position: relative;

      @media (min-width: var.$lg) {
        display: none;
      }

      &__icon {
        position: absolute;
        top: 19px;
        right: 10px;
        cursor: pointer;
      }
    }

    &__switcher {
      background: var.$gray-light;
      padding: 4px;
      border-radius: 12px;
      display: flex;

      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 28px;
      }

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 9.75px;
        height: 40px;
        width: 160px;
        border-radius: 8px;

        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: var.$black;
        cursor: pointer;
        transition: all 200ms;

        & > div {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        &--active {
          background: var.$white;
        }
      }
    }

    &__categories {
      position: relative;
      display: grid;
      align-items: flex-start;
      grid-template:
        [row1-start] "area1 area2 area3" auto [row1-end]
        [row2-start] "area4 area4 area4" auto [row2-end] / 1fr 1fr 1fr;
      grid-gap: 16px;

      &__preloader {
        position: fixed !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @media (min-width: var.$lg) {
          position: absolute !important;
        }
      }

      &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__label {
          color: var.$secondary;
        }

        &__item {
          color: var.$black;
          padding: 15px 0;
          margin-top: 12px;
          width: 100%;
          text-align: center;
          border: 1px solid var.$gray-light;
          border-radius: 8px;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background: var.$gray-dark;
          }

          @media (min-width: var.$lg) {
            padding: 15px 27px;
          }
        }
      }

      @media (min-width: var.$lg) {
        min-width: 735px;
        min-height: 408px;
        grid-gap: 24px;
        grid-template: [row1-start] "area1 area2 area3 area4" auto [row1-end];
      }
    }
  }

  &__switcher {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 12px;
    margin-top: 28px;
    color: var.$black;
    cursor: pointer;
  }
}
</style>
