<template>
  <div class="main-screen" v-if="mainScreen">
    <img
      class="background-image"
      :src="mainScreen.backgroundImage.src"
      :alt="mainScreen.backgroundImage.alt"
      :srcset="mainScreen.backgroundImage.srcset"
    />

    <div class="container">
      <div class="content">
        <div class="heading__title" v-html="mainScreen.heading"></div>

        <div class="search-box">
          <TourSearch
            :autofocus="!this.$device.mobile"
            @updateLocation="location = [$event]"
            @updateDate="date = [$event]"
            @switchPeriod="switchPeriod($event)"
          />

          <button type="submit" class="search-btn" @click="onSubmit">
            <span>{{ searchLabel }}</span>
            <Icon icon="icon-search" class="search-btn-icon" />
          </button>

          <a
            :href="mainScreen.watchAllTours.link"
            class="watch-all-tours"
            @click="trackEvent('Main page Search', 'Click All tours')"
          >
            {{ mainScreen.watchAllTours.placeholder }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/index.vue";
import TourSearch from "@/components/ui-kit/tour-search/index.vue";
import {mapState} from "vuex";
import { i18n } from "@/plugins/i18n";
import { ytEvents } from "@/plugins/analytics";
import { format } from "date-fns";


export default {
  components: {
    Icon,
    TourSearch,
  },
  data: () => ({
    searchLabel: i18n.t("mainPreview.search"),
    strictPeriod: true,
    location: [],
    date: [],
  }),
  computed: {
    ...mapState({
      language: (state) => state.language.language,
      mainScreen: (state) => state.mainScreen.mainScreen,
      heading: (state) => state.mainScreen.heading,
      watchAllTours: (state) => state.mainScreen.watchAllTours,
      backgroundImage: (state) => state.mainScreen.backgroundImage,
    }),
    serpPath() {
      let postfix = '/';
      if (this.location
        && this.location.length === 1) {
        const location = this.location[0];
        if(location.type !== "distance") {
          postfix += location.type + '/' + location.code
        }
      }
      return '/tours' + postfix;
    },
    filtersQuery() {
      const query = [
        `is_period_strict=${+this.strictPeriod}`,
        `lang=${this.language.id}`,
      ];

      if (this.location) {
        const queryLocation = [];

        this.location.forEach((location, idx) => {
          if (location.type === "distance") {
            queryLocation.push(`loc[${idx}][type]=${location.type}`);
            queryLocation.push(`loc[${idx}][lat]=${location.lat}`);
            queryLocation.push(`loc[${idx}][lon]=${location.lon}`);
            queryLocation.push(`loc[${idx}][radius]=${location.radius}`);
          } else {
            queryLocation.push(`loc[${idx}][type]=${location.type}`);
            queryLocation.push(`loc[${idx}][id]=${location.id}`);
          }
        });

        query.push(...queryLocation);
      }

      if (this.date) {
        const queryDates = [];

        this.date.forEach(([dateFrom, dateTo, dateType], idx) => {
          const from = format(dateFrom, "dd.MM.yyyy");
          const to = format(dateTo, "dd.MM.yyyy");

          if (dateType === "month") {
            queryDates.push(`period[${idx}][month]=${from}`);
          } else {
            queryDates.push(`period[${idx}][from]=${from}`);
            queryDates.push(`period[${idx}][to]=${to}`);
          }
        });

        query.push(...queryDates);
      }
      return query.join("&");
    },
  },
  methods: {

    onSubmit() {
      this.trackEvent("Main page Search", "Click Search button");
      fakeWindow.location = (`${this.serpPath}?${this.filtersQuery}`);
    },
    trackEvent(category, name, label) {
      ytEvents.track(category, name, label);
    },
    switchPeriod(strictPeriod) {
      this.strictPeriod = strictPeriod;
    },
    setCountryCode() {
      this.$store.dispatch('country/setCountryCode');
    },
  },
  mounted() {
    this.setCountryCode();
  }
};
</script>

<style lang="scss">
@use "@/assets/scss/libraries/elements/date-selector-new.scss";
//TODO: перенести в глобальный asset
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

.main-screen {
  * {
    font-family: "Inter", sans-serif;
  }
}

.heading__title {
  margin: 0;
  margin-bottom: 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.03em;

  color: var.$neutral-100;

  h1 {
    margin: 0;
    padding: 0;
  }

  span {
    color: var.$green-1;
  }

  @media (min-width: var.$lg) {
    margin-bottom: 54px;

    h1 {
      font-size: 52px;
      line-height: 100%;
    }
  }

  @media (min-width: var.$xl) {
    margin-bottom: 54px;

    h1 {
      font-size: 64px;
      line-height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.main-screen {
  position: relative;

  width: 100%;
  height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: var.$lg) {
    height: 700px;
  }
}

.container {
  position: relative;

  width: 100%;
  max-width: 1440px;

  margin: 0 auto;
  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: var.$lg) {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 47px));

    width: auto;

    margin-bottom: 0;
  }

  @media (min-width: var.$xl) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 47px));

    width: auto;

    margin-bottom: 0;
  }
}

.background-image {
  position: absolute;

  width: 100%;
  height: 100%;

  object-fit: cover;
  filter: brightness(70%);

  @media (min-width: var.$lg) {
    object-position: center 20%;
  }
}

.content {
  padding: 12px;
  width: 100%;

  @media (min-width: 1335px) {
    width: 75vw;

    padding: 0;
    margin-left: 229px;
  }

  @media (min-width: var.$lg) {
    width: 80vw;

    margin-left: 0;
    padding: 0;
  }
}

.search-box {
  display: flex;
  grid-gap: 16px;
  position: relative;
  border-radius: 16px;
  flex-direction: column;

  @media (min-width: var.$lg) {
    padding: 8px;
    max-width: 844px;
    flex-direction: row;
    border: 1px solid #fff6e5;
    background-color: var.$teal-100;
  }
}
.search-box.wide {
  @media (min-width: var.$lg) {
    max-width: 1030px;
  }
}
.search-btn {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px;

  color: var.$neutral-100;
  border: none;
  background-color: var.$green-1;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;

  cursor: pointer;

  // TODO: fix icon
  svg {
    color: transparent;
    stroke: var.$neutral-100;
  }

  &:hover {
    background-color: lighten(var.$green-1, 10%);
  }

  @media (min-width: var.$lg) {
    max-width: 144px;

    padding: 0;
  }
}

.search-btn-icon {
  margin-left: 8px;
}

.watch-all-tours {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  color: var.$neutral-100;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &:before {
    position: absolute;
    top: 97%;
    content: "";
    width: 100%;
    height: 1px;
    background-color: var.$neutral-100;
  }

  @media (min-width: var.$lg) {
    top: calc(100% + 16px);
    left: auto;
    right: 0;
  }
}

.selector-main-form {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .hide {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    visibility: collapse;
  }

  @media (min-width: var.$lg) {
    width: 144px;
  }
}
</style>
